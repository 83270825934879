export const tableSelectFields = [
  {
    value: 'collections',
    label: 'Collections',
  },
  {
    value: 'sources',
    label: 'Sources',
  },
  {
    value: 'entries',
    label: 'Entries',
  },
];
