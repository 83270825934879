export const SEARCH_REQUEST = 'SEARCH_REQUEST';
export const SEARCH_SUBMITTING = 'SEARCH_SUBMITTING';
export const SEARCH_SUCCESSFUL = 'SEARCH_SUCCESSFUL';
export const SEARCH_FAILED = 'SEARCH_FAILED';
export const SEARCH_SORT = 'SEARCH_SORT';
export const SEARCH_SET_SORT = 'SEARCH_SET_SORT';
export const SEARCH_RESET_SORT = 'SEARCH_RESET_SORT';
export const SEARCH_SELECT_PAGE = 'SEARCH_SELECT_PAGE';
export const SEARCH_SET_SORT_AND_SORT = 'SEARCH_SET_SORT_AND_SORT';
export const SEARCH_SET_RESULTS_PER_PAGE =
  'SEARCH_SET_RESULTS_PER_PAGE';
export const SEARCH_UPDATE_RESULTS = 'SEARCH_UPDATE_RESULTS';

export const MODAL_OPEN_EDIT_ROW_FORM = 'MODAL_OPEN_EDIT_ROW_FORM';
export const MODAL_SUBMIT_UPDATE = 'MODAL_SUBMIT_UPDATE';
export const MODAL_OPEN_CREATE_ROW_FORM = 'MODAL_OPEN_CREATE_FORM';
export const MODAL_OPEN_SEARCH_GUIDE = 'MODAL_OPEN_SEARCH_GUIDE';
export const MODAL_OPEN_BOOK = 'MODAL_OPEN_BOOK';
export const MODAL_OPEN_CITATION = 'MODAL_OPEN_CITATION';
export const MODAL_OPEN_DISCLAIMER = 'MODAL_OPEN_DISCLAIMER';
export const MODAL_SUBMIT_CREATE = 'MODAL_SUBMIT_CREATE';
export const MODAL_OPEN_VIEW_ROW = 'MODAL_OPEN_VIEW_ROW';
export const MODAL_SUBMIT_DELETE = 'MODAL_SUBMIT_DELETE';
export const MODAL_VIEW_ROW = 'MODAL_VIEW_ROW';
export const MODAL_SUBMITTING_REQUEST = 'MODAL_SUBMITTING_REQUEST';
export const MODAL_REQUEST_SUCCESSFUL = 'MODAL_REQUEST_SUCCESSFUL';
export const MODAL_REQUEST_FAILED = 'MODAL_REQUEST_FAILED';
export const MODAL_CLOSE = 'MODAL_CLOSE';
